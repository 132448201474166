
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import {
  searchDropdown,
  showSearchDropdown,
  hideSearchDropdown
} from './index'
import { linkTo } from '@/layouts/side-menu'
import { mapGetters } from 'vuex'
import context from '@cloudfun/core'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu
    // DarkModeSwitcher,
  },
  setup () {
    const application = context.root
    const policy = application?.policy
    const route = useRoute()
    const router = useRouter()
    const formattedMenu = ref([])
    const topMenu = computed(() => policy?.makeMenu('/top-menu') || [])

    watch(
      computed(() => route.fullPath),
      () => {
        formattedMenu.value = $h.toRaw(topMenu.value)
      }
    )

    const adminTitle = computed(() => application?.model.state.configuration.value.AdminTitle || "Cloudfun Admin")

    onMounted(async () => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('app')
      formattedMenu.value = $h.toRaw(topMenu.value)
      if (application) application.model.dispatch('configuration/read')
    })

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      formattedMenu,
      router,
      linkTo,
      isGuest: !application?.user.Id,
      adminTitle
    }
  },
  computed: {
    ...mapGetters(['user']),
    homePath () {
      const path = this.$route.path
      if (path.startsWith('/simple-menu')) return '/simple-menu'
      if (path.startsWith('/top-menu')) return '/top-menu'
      return '/'
    }
  },
  methods: {
    logout () {
      if (!this.$user.Id) {
        this.$router.push('/login')
        return
      }
      this.$model.dispatch('logout').then(
        () => this.$router.push('/login')
      )
    }
  },
  watch: {
    user (current) {
      this.isGuest = !current.Id
    }
  }
})
